export const paymentOptionsMapping: any = {
  Mobile_Wallet: 'Mobile_Wallet',
  'Mobile Wallet': 'Mobile_Wallet',
  'Cash through AMAN': 'Cash',
  'Cash Through Fawry': 'Fawry',
  'Credit & Debit Card': 'creditCard',
  'Credit Card': 'creditCard',
  Qassatly: 'Qassatly',
  Meeza: 'Meeza',
  '6 Months - NBE': '6 Months - NBE',
  '12 Months - NBE': '12 Months - NBE',
  '18 Months - NBE': '18 Months - NBE',
  PaySky: 'PaySky',
  Installments: 'Installments',
  "6 Months - Installments" : "6 Months - Installments",
  "12 Months - Installments" : "12 Months - Installments",
  "18 Months - Installments" : "18 Months - Installments",
  'Card Payments': 'Card_Payments',
  'Offline Payment': 'Offline Payment',
  'ValU': 'ValU',
  'Souhoola': 'Souhoola',
  '6 Months - BM': '6 Months - BM',
  '12 Months - BM': '12 Months - BM',
  '18 Months - BM': '18 Months - BM',
  'Aman Installments': 'Aman Installments',
  Contact: 'Contact',
};
