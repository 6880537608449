export const environment = {
  production: true,
  DOMAIN: "www.easykash.net",
  BASE_URL: 'https://www.easykash.net/',
  API_URL: 'https://back.easykash.net/api/',
  OtlobxUrl:"https://otlobx.com/api/",
  BILLER_URL: 'https://back.easykash.net/biller/api/bill/',
  GATE_URL: 'https://pay.easykash.net/backend/',
  UPG_LIGHTBOX: 'https://upg.egyptianbanks.com:2008/INVCHOST/js/Lightbox.js',
  PaySky_LIGHTBOX: 'https://cube.paysky.io:6006/js/LightBox.js',
  delay: 5000,
  NEW_GATE_LIGHTBOX: 'https://pay.easykash.net/backend/lightBox/sdk/v2',
  SECURE_KEY: 'LFi3DiVB6obLigppY5ud83U3JoHsnKfHv4kc93F3lSxY',
  RECAPTCHA_KEY: '6LfiTuIeAAAAAPpUOBAx53W4OdmtjEj_HahAiYGU',
  billerClientId : 'PK_adly0z'
};
