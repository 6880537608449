import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FetchProductTypeCategoriesService } from '../../../services/fetch-product-type-categories.service';
import { MandatoryFieldsService } from '../../../services/mandatory-fields.service';
import { BaseField } from '../../baseField';

@Component({
  selector: 'app-product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.scss']
})
export class ProductCategoriesComponent extends BaseField implements OnInit, AfterViewInit {
  arrayOfOptions: any[] = [];
  mandatoryCategories = ['Category'];
  mandatory: boolean = false;
  @Input() defaultValue: any;
  searchKey: string = '';

  constructor(
    private fetchCategories: FetchProductTypeCategoriesService,
    private mandatoryFieldsService: MandatoryFieldsService,
    private translate: TranslateService,
  ) {
    super();
  }

  async ngOnInit() {
    if (localStorage.getItem('language') === 'ar') {
      this.searchKey = 'nameAr';
    } else {
      this.searchKey = 'name';
    }
    this.mandatory = this.mandatoryFieldsService.isMandatory(this.mandatoryCategories, this.fieldObject.fieldName) || false;
    try {
      this.fetchCategories.setParams({
        productTypeName: "physical product"
      });
    } catch (err) {
      console.log(err);
    }
  }

  async ngAfterViewInit() {
    const result: any[] = await this.fetchCategories.make();
    for (let i = 0; i < result.length; i++) {
      switch (result[i].name) {
        case 'Automotive Parts':
          result[i].nameAr = 'قطع غيار السيارات';
          break;
        case 'Car Accessories':
          result[i].nameAr = 'اكسسوارات السيارات';
          break;
        case 'Clothings':
          result[i].nameAr = 'ملابس';
          break;
        case 'Digital Books / Books':
          result[i].nameAr = 'كتب / كتب رقمية';
          break;
        case 'Electronics':
          result[i].nameAr = 'إلكترونيات';
          break;
        case 'Fashion':
          result[i].nameAr = 'موضة';
          break;
        case 'Fitness Products':
          result[i].nameAr = 'منتجات اللياقة البدنية';
          break;
        case 'Flowers':
          result[i].nameAr = 'زهور';
          break;
        case 'Food & Beverage':
          result[i].nameAr = 'طعام وشراب';
          break;
        case 'Games / Toys / Gifts':
          result[i].nameAr = 'ألعاب / هدايا';
          break;
        case 'Gifts / Crafts':
          result[i].nameAr = 'هدايا / حرف';
          break;
        case 'Gym Supplies':
          result[i].nameAr = 'لوازم الصالة الرياضية';
          break;
        case 'Hand Made Products':
          result[i].nameAr = 'منتجات يدوية';
          break;
        case 'Home Decoration':
          result[i].nameAr = 'تزيين المنزل';
          break;
        case 'Jewelry & Accessories':
          result[i].nameAr = 'المجوهرات والاكسسوارات';
          break;
        case 'Makeup & Cosmetics / Beauty Products':
          result[i].nameAr = 'مكياج ومستحضرات تجميل';
          break;
        case 'Mobile Accessories':
          result[i].nameAr = 'اكسسوارات الهاتف المحمول';
          break;
        case 'Music Products':
          result[i].nameAr = 'منتجات الموسيقى';
          break;
        case 'Optical Goods':
          result[i].nameAr = 'السلع البصرية';
          break;
        case 'Paintings':
          result[i].nameAr = 'لوحات';
          break;
        case 'Phtography Products':
          result[i].nameAr = 'منتجات التصوير الفوتوغرافي';
          break;
        case 'Stationaries':
          result[i].nameAr = 'ادوات مكتبيه';
          break;
        case 'Technology Products':
          result[i].nameAr = 'منتجات التكنولوجيا';
          break;
        case 'Tools / Equipments / Supplies':
          result[i].nameAr = 'أدوات / معدات / لوازم';
          break;
        case 'Educational':
          result[i].nameAr='تعليمى';
          break;
        case 'Others':
          result[i].nameAr = 'آخرون';
          break;
        default:
          break;
      }
    }
    this.translate.onLangChange.subscribe((lang) => {
      console.log(lang.lang)
      if (lang.lang === 'ar') {
        this.searchKey = 'nameAr';
      } else {
        this.searchKey = 'name';
      }
    });
    this.arrayOfOptions = result;
  }

  selectedValue(event: number) {
    //console.log('selectedValue', event);
    if(event)this.formControlRef.setValue(event);
  }

  translateFieldName(fieldObject: any) {
    if (localStorage.getItem("language") === "ar") {
      return fieldObject.fieldNameAr;
    } else {
      return fieldObject.fieldName;
    }
  }
}
