import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-select-box-search',
  templateUrl: './select-box-search.component.html',
  styleUrls: ['./select-box-search.component.scss'],
})

export class SelectBoxSearchComponent implements OnInit, OnChanges {
  @Input() placeholder: string = "";
  @Input() searchKey: string = "name";
  @Input() returnKey: string = "id";
  @Input() list!: any[];
  @Output() valueChange = new EventEmitter();
  mappedList: any[] = [];
  @Input() defaultValue: any; // for update forms
  public config: any = {};

  constructor() { }

  ngOnInit() {
    this.config = {
      search: true,
      placeholder: this.placeholder,
      noResultsFound: 'No results found!',
      height: '200px'
    }
  }

  ngOnChanges() {
    this.getMappedList();
  }

  getMappedList() {
    if (this.list) {
      this.mappedList = this.list.map(ele => {
        return {
          id: ele[this.returnKey],
          description: ele[this.searchKey]
        };
      });
    }
  }

  valueChanged(event: any) {
    if (event.value.id) {
      this.valueChange.emit(event.value.id);
    } else {
      this.valueChange.emit(null);
      console.log('selected value', event)
    }
  }
}
